import { CSSProperties } from "react";
import { BannerCarousel } from "./banner-carousel";
import { BannerSlide } from "./banner-slide";
import bannerDesktop from "./banner-fourrier.jpg";
import bannerMobile from "./banner-fourrier-mobile.jpg";
import { A } from "../a";

type Props = {
  style?: CSSProperties;
};

export function Banner({ style }: Props) {
  return (
    <BannerCarousel
      style={style}
      slides={[
        <BannerSlide
          key="banner-slide-1"
          srcDesktop={bannerDesktop}
          srcMobile={bannerMobile}
          alt="August Sale"
          // the banner will be removed on this date
          endDateExcluded={{
            day: 15,
            month: 9,
            year: 2024,
          }}
          markup={<A to={`/weekly/auctions/all?search=Fourrier`} />}
          /**
           * The markup takes the whole size of the slide.
           * Put eventual links here
           */
          // markup={<A to="https://a.b.c">Summer Sales 15% off</A>}
        />,
      ]}
    />
  );
}
