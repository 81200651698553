import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { PageLots } from "./lots";
import { PageBarrels } from "./barrels";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import { NavLinks } from "src/app/components/nav-links";
import { path } from "src/app/routes/path";
import { PageExperiences } from "./experiences";
import { PAGE_TITLE } from "src/app/const";
import { PageBottles } from "./bottles";
import { PageStats } from "src/app/components/page-stats";
import { useMyAssetsStats } from "src/app/api/queries_to_rename_later";
import { Spacer } from "src/app/components/spacer";

export const PageMyAssets = () => {
  const { pathname } = useLocation();

  if (pathname === path.myAssets.value()) {
    return <Redirect to={path.myAssets.lots()} push={false} />;
  }
  return <PageContent />;
};

const PageContent = () => {
  usePageTitle(PAGE_TITLE.MY_ASSETS());
  const statsQuery = useMyAssetsStats();

  return (
    <section className="collections-container physical-page-body">
      <Spacer size={16} />
      <PageStats stats={statsQuery.data} />
      <Spacer size={24} />
      <NavLinks
        links={[
          { label: "Lots", to: path.myAssets.lots() },
          { label: "Barrels", to: path.myAssets.barrels() },
          // { label: "Bottles", to: path.myAssets.bottles() },
          { label: "Experiences", to: path.myAssets.experiences() },
        ]}
      />
      <div>
        <Switch>
          <Route path={path.myAssets.lots()} component={PageLots} />
          <Route path={path.myAssets.barrels()} component={PageBarrels} />
          <Route path={path.myAssets.bottles()} component={PageBottles} />
          <Route
            path={path.myAssets.experiences()}
            component={PageExperiences}
          />
        </Switch>
      </div>
    </section>
  );
};
