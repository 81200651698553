import { useIsMutating } from "@tanstack/react-query";
import { ReactNode } from "react";
import { useAlertPopup } from "src/app/components/alert-popup/store";
import { Button } from "src/app/components/button";
import { HtmlDialog } from "src/app/components/html-dialog";
import { useDebounceValue } from "src/app/hooks/useDebounceValue";
import { cn } from "src/app/utils/cn";
import styles from "./modal.module.css";
import { useBidProcessingFeesQuery } from "src/app/api/queries_to_rename_later";
import { AuctionOfBarrel } from "src/app/models/auctions/AuctionOfBarrel";
import { AuctionOfLot } from "src/app/models/auctions/AuctionOfLot";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { isAuctionOfBarrel } from "src/app/models/auctions";

type Props = {
  title: string;
  content: ReactNode | string;
  auction: AuctionOfBarrel | AuctionOfLot;
  onClose?: () => void;
  closeButton?: {
    label: string;
  };
  actionButton?: {
    label: string;
    onClick?: (onClose: () => void) => void;
  };
  bidDetails?: {
    price: number;
    quantity: number;
  };
  showTaxDisclaimer?: boolean;
};

export default function Modal({
  title,
  content,
  onClose,
  closeButton,
  actionButton,
  bidDetails,
  showTaxDisclaimer = false,
  auction,
}: Props) {
  const { data } = useBidProcessingFeesQuery(
    {
      auction_type: auction.type_product,
      id_auction: auction.id,
      quantity: bidDetails?.quantity || 1,
      single_bid: bidDetails?.price || 1,
    },
    { enabled: !!bidDetails },
  );

  const alertPopup = useAlertPopup();
  const isMutating = !!useIsMutating();
  const isMutatingDebounced = useDebounceValue(isMutating, 300);
  // delay has to match the closing animation duration
  const debouncedIsOpen = useDebounceValue(alertPopup.currentPopup.isOpen, 150);
  const isClosing = !alertPopup.currentPopup.isOpen && debouncedIsOpen;

  const isOpen = alertPopup.currentPopup.isOpen || debouncedIsOpen;

  const handleClose = () => {
    if (!isOpen) return;
    alertPopup.close();
    onClose?.();
  };

  return (
    <HtmlDialog
      mandatory={false}
      isOpen={isOpen}
      onClose={handleClose}
      className={cn(styles.root, isClosing && styles.closing)}
    >
      <div className={styles.content}>
        <h2>{title}</h2>
        {!!bidDetails && (
          <div className={styles.bidDetails}>
            <div className={styles.top}>
              <div className={styles.detailItem}>
                <div
                  className={styles.value}
                >{`${bidDetails.quantity * (auction.options.quantities.mul || 1)} ${isAuctionOfBarrel(auction) ? "L" : bidDetails.quantity > 1 ? "lots" : "lot"}`}</div>
                <div className={styles.label}>Quantity selected</div>
              </div>
              <div className={styles.detailItem}>
                <div className={styles.value}>
                  {formatCurrency(bidDetails.price * bidDetails.quantity)}
                </div>
                <div className={styles.label}>Price</div>
              </div>
            </div>
            <div className={styles.bottom}>
              + {formatCurrency(data)} processing fees
            </div>
          </div>
        )}
        <div className={styles.description}>{content}</div>
        {!!showTaxDisclaimer && (
          <div className={styles.disclaimer}>
            Taxes / Duties / VAT if applicable, will be calculated at the moment
            of the shipment based on the shipping country.
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.buttonsWrapper}>
          {!!actionButton && (
            <Button
              as={"button"}
              className={styles.button}
              label={actionButton.label}
              disabled={isMutating}
              onClick={() => {
                actionButton.onClick?.(handleClose);
              }}
              isLoading={isMutatingDebounced}
            />
          )}
          <Button
            className={styles.button}
            label={
              actionButton
                ? closeButton?.label || "Cancel"
                : closeButton?.label || "OK"
            }
            onClick={handleClose}
            disabled={isMutating}
            variant={actionButton ? "outlined" : "contained"}
            isLoading={isMutatingDebounced}
          />
        </div>
      </div>
    </HtmlDialog>
  );
}
