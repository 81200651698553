import "./styles.css";
import { Fragment, useState } from "react";
import { useGetBannersQuery } from "src/app/api/queries";
import { filter, findKey, omit, range, size } from "lodash";
import { Redirect, useParams } from "react-router-dom";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import { SelectMultipleFromData } from "src/app/components/form-elements/select-multiple/from-data";
import {
  AlertPopup,
  AlertPopupMeta,
} from "src/app/modules/Includes/LayoutSide/components/common/alert-popup";
import SkeletonCollectionCard from "src/app/modules/Includes/CustomerSide/components/skeleton/SkeletonCollectionCard";
import { NoBidsNew } from "src/app/modules/Includes/CustomerSide/components/common/NoBidsNew";
import { AdsBanner } from "./ads-banner";
import { NavLinks } from "src/app/components/nav-links";
import { path } from "src/app/routes/path";
import { PAGE_NAME, PAGE_TITLE } from "src/app/const";
import { ValueOf, inferDataType } from "src/app/utils/types";
import { useAuctionCountQuery } from "src/app/api/auction-count";
import {
  Auction,
  isAuctionOfBarrel,
  isAuctionOfLot,
} from "src/app/models/auctions";
import { useAuctionsQuery } from "src/app/api/auctions";
import { useSelectMultipleFromData } from "src/app/components/form-elements/select-multiple/useSelectMultipleFromData";
import { Warnings } from "./warnings";
import { StickyControls } from "src/app/components/sticky-controls";
import { Grid } from "src/app/components/auction-card/lg/grid";
import { useInputAutocomplete } from "src/app/components/form-elements/input-autocomplete/useInputAutocomplete";
import { InputAutocomplete } from "src/app/components/form-elements/input-autocomplete";
import { useScrollToAuction } from "src/app/hooks/useScrollToAuction";
import { AuctionCardLg } from "src/app/components/auction-card/lg";

type WeeklyPageName = ValueOf<typeof PAGE_NAME.WEEKLY>;

export const WeeklyAuctionsPage = () => {
  usePageTitle(PAGE_TITLE.WEEKLY_AUCTIONS());
  const { pageName } = useParams<{
    pageName?: WeeklyPageName;
  }>();

  const { data: count } = useAuctionCountQuery();

  if (!count) {
    return null;
  }

  const hasNoAuction = !Object.values(count).reduce((acc, n) => acc + n, 0);

  if (hasNoAuction) {
    return <NoBidsNew title="No live Auction" />;
  }

  const showAllTab = size(filter(omit(count, ["all"]), Boolean)) > 1;

  const defaultTabPath = showAllTab
    ? path.weekly.value(PAGE_NAME.WEEKLY.ALL)
    : path.weekly.value(
        findKey(omit(count, ["all"]), Boolean) as ValueOf<
          Omit<typeof PAGE_NAME.WEEKLY, "ALL">
        >,
      );

  if (!pageName) {
    return <Redirect to={defaultTabPath} />;
  }

  return <Content showAllTab={showAllTab} />;
};

const Content = ({ showAllTab = false }) => {
  const { pageName } = useParams<{
    pageName: WeeklyPageName;
  }>();

  const [alertPopupMeta, setAlertPopupMeta] = useState<AlertPopupMeta | null>(
    null,
  );

  const bannersQuery = useGetBannersQuery({
    enabled: pageName === PAGE_NAME.WEEKLY.CHARITY,
  });
  const { data: count } = useAuctionCountQuery();

  const auctionsQuery = useAuctionsQuery({ pageName });

  const stickyControlsRef = useScrollToAuction({
    enabled: !!auctionsQuery.data,
    getCardId,
  });

  const inputAutocomplete = useInputAutocomplete({
    inferDataType: inferDataType<Auction>(),
    getSuggestionText: (auction) => auction.description,
  });

  const selectMultiple = useSelectMultipleFromData({
    inferDataType: inferDataType<Auction>(),
    config: [
      {
        groupName: "Product Type",
        getValue: (auction) =>
          isAuctionOfLot(auction) ? auction.productCategories : [],
      },
      {
        groupName: "Availability",
        getValue: (auction) =>
          !auction.is_no_deposit ? "In Warehouse" : "With producer",
      },
      {
        groupName: "Region",
        getValue: (auction) =>
          isAuctionOfLot(auction)
            ? auction.lot?.components.map((comp) => comp.region)
            : isAuctionOfBarrel(auction)
              ? auction.barrel.product_region
              : [],
      },
      {
        groupName: "Color",
        getValue: (auction) =>
          isAuctionOfLot(auction)
            ? auction.lot?.components.map((comp) => comp.color)
            : isAuctionOfBarrel(auction)
              ? auction.barrel.product_color
              : "",
      },
      {
        groupName: "Price Range",
        getValue: (auction) => {
          if (auction.initial_price <= 100) return " Up to 100€"; // the space before Up puts the option in first poisition
          if (auction.initial_price <= 250) return "101€ - 250€";
          if (auction.initial_price <= 500) return "250€ - 500€";
          return "Over 500€";
        },
      },
      {
        groupName: "Bottle Size",
        getValue: (auction) =>
          isAuctionOfLot(auction)
            ? auction.lot?.components.map((comp) => `${comp.size} L`)
            : [],
      },
      {
        groupName: "Vintage",
        getValue: (auction) => {
          if (isAuctionOfLot(auction)) {
            return auction.lot?.components.map((comp) =>
              !+comp.vintage ? " No Vintage" : comp.vintage,
            );
          }
          if (isAuctionOfBarrel(auction)) {
            return !auction.barrel.product_vintage
              ? " No Vintage"
              : auction.barrel.product_vintage;
          }
        },
      },
      {
        groupName: "Winery",
        getValue: (auction) =>
          isAuctionOfLot(auction)
            ? auction.lot?.components.map((comp) => comp.builder.name)
            : isAuctionOfBarrel(auction)
              ? auction.barrel.builder.name
              : [],
      },
    ],
  });

  const filteredAuctions = (auctionsQuery.data || [])
    .filter((auction) => !auction.isArchive)
    .filter(inputAutocomplete.filterFn)
    .filter(selectMultiple.filterFn);

  return (
    <>
      {!!alertPopupMeta && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setAlertPopupMeta(null)}
        />
      )}

      <div>
        {pageName === PAGE_NAME.WEEKLY.CHARITY && <Warnings />}

        <StickyControls
          outerRef={stickyControlsRef}
          slot1={() => (
            <NavLinks
              links={[
                !!showAllTab && {
                  to: path.weekly.value("all"),
                  label: "All",
                  count: count?.all,
                },
                !!count?.["charity"] && {
                  to: path.weekly.value("charity"),
                  label: "Charity",
                  count: count.charity,
                },
                !!count?.["collections"] && {
                  to: path.weekly.value("collections"),
                  label: "Collections",
                  count: count["collections"],
                },
                !!count?.["single-lots"] && {
                  to: path.weekly.value("single-lots"),
                  label: "Single Lots",
                  count: count["single-lots"],
                },
                !!count?.barrels && {
                  to: path.weekly.value("barrels"),
                  label: "Barrels",
                  count: count["barrels"],
                },
              ].filter(Boolean)}
            />
          )}
          slot2={(scrollToTop) => (
            <SelectMultipleFromData
              placeholder="Filters"
              data={filteredAuctions}
              values={selectMultiple.values}
              onChange={(values) => {
                scrollToTop();
                selectMultiple.setValues(values);
              }}
              config={selectMultiple.config}
            />
          )}
          slot3={(scrollToTop) => (
            <InputAutocomplete
              data={filteredAuctions}
              getSuggestionText={inputAutocomplete.getSuggestionText}
              value={inputAutocomplete.value}
              onChange={(value) => {
                scrollToTop();
                inputAutocomplete.setValue(value);
              }}
            />
          )}
        />

        <Grid isLoading={auctionsQuery.isLoading}>
          {(!!auctionsQuery.isLoading ||
            (!!auctionsQuery.isFetching && !filteredAuctions.length)) &&
            range(0, 4).map((i) => (
              <SkeletonCollectionCard key={`skel-card-arch-${i}`} />
            ))}

          {!auctionsQuery.isLoading &&
            !auctionsQuery.isFetching &&
            !filteredAuctions.length && <NoBidsNew title="Nothing found" />}

          {filteredAuctions.map((auction, auctionIndex) => (
            <Fragment
              key={`auction-xl-${auction.id_auction}-${auction.type_product}`}
            >
              <AuctionCardLg.Card
                auction={auction}
                cardId={getCardId(auction.id)}
              />

              {/* Add banner every two auctions  */}
              {shouldShowBanner({
                pageName,
                auctionIndex,
                auctions: filteredAuctions,
                bannersData: bannersQuery.data,
              }) && (
                <AdsBanner
                  src={`data:image/png;base64,${
                    bannersQuery.data[Math.floor(auctionIndex / 2)]?.image
                  }`}
                />
              )}
            </Fragment>
          ))}
        </Grid>
      </div>
    </>
  );
};

type Config = {
  pageName: WeeklyPageName;
  auctionIndex: number;
  auctions: Auction[];
  bannersData: Array<{ image: string }>;
};

function shouldShowBanner({
  pageName,
  auctionIndex,
  auctions,
  bannersData,
}: Config) {
  if (pageName !== PAGE_NAME.WEEKLY.CHARITY) return false;
  // no banner after the last auction
  if (auctionIndex === auctions.length - 1) return false;
  // check if there is a banner image
  if (!bannersData[Math.floor(auctionIndex / 2)]?.image) return false;
  return (auctionIndex + 1) % 2 === 0;
}

function getCardId(id: number) {
  return `card-xl-${id}`;
}
