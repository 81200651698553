import { Button } from "src/app/components/button";
import styles from "./you-might-also-like.module.css";
import { Auction, isAuctionOfBarrel } from "src/app/models/auctions";
import { useAuctionsQuery } from "src/app/api/auctions";
import { ID_AUCTION_TYPE, PageName } from "src/app/const";
import { AuctionCardMd } from "src/app/components/auction-card/md";
import { NoBidsNew } from "src/app/modules/Includes/CustomerSide/components/common/NoBidsNew";
import { A } from "src/app/components/a";
import { path } from "src/app/routes/path";
import { Grid } from "src/app/components/auction-card/lg/grid";
import SkeletonCollectionCard from "src/app/modules/Includes/CustomerSide/components/skeleton/SkeletonCollectionCard";
import { range } from "lodash";
import { AuctionCardLg } from "src/app/components/auction-card/lg";

type Props = {
  auction: Auction;
};

const limit = 6;

export const YouMightAlsoLike = ({ auction }: Props) => {
  const auctionsQuery = useAuctionsQuery(getAuctionsQueryConfig(auction));
  const auctions = (auctionsQuery.data || []).filter(
    (a) => a.id !== auction.id,
  );

  if (!auctions.length) return null;

  return (
    <section>
      <h3 className={styles.title}>You might also like</h3>

      {auction.isForStore ? (
        <AuctionCardMd.Grid
          isLoading={auctionsQuery.isLoading}
          emptyFallback={<NoBidsNew title="Nothing found" />}
        >
          {auctions.map((auction) => (
            <AuctionCardMd.Card
              key={`shop-auction-card-key-${auction.id_auction}-${auction.type_product}`}
              auction={auction}
              cardId={`auction-${auction.type_product}-${auction.id}`}
            />
          ))}
        </AuctionCardMd.Grid>
      ) : (
        <Grid isLoading={auctionsQuery.isLoading}>
          {(!!auctionsQuery.isLoading ||
            (!!auctionsQuery.isFetching && !auctions.length)) &&
            range(0, 4).map((i) => (
              <SkeletonCollectionCard key={`skel-card-arch-${i}`} />
            ))}

          {!auctionsQuery.isLoading &&
            !auctionsQuery.isFetching &&
            !auctions.length && <NoBidsNew title="Nothing found" />}

          {auctions.map((auction) => (
            <AuctionCardLg.Card
              key={`auction-xl-${auction.id_auction}-${auction.type_product}`}
              auction={auction}
              cardId={`auction-${auction.id_auction}`}
            />
          ))}
        </Grid>
      )}

      <ViewAll auction={auction} />
    </section>
  );
};

function ViewAll({ auction }: { auction: Auction }) {
  const to = getViewAllLinkTo(auction);

  return (
    <div className={styles.viewAllRoot}>
      <Button
        as={A}
        to={to}
        className={styles.viewAllButton}
        variant="outlined"
        label="View All"
      />
    </div>
  );
}

function getAuctionsQueryConfig(auction: Auction) {
  const pageName: PageName = auction.isForStore
    ? "shop"
    : isAuctionOfBarrel(auction)
      ? "barrels"
      : auction.type.id === ID_AUCTION_TYPE.COLLECTION
        ? "collections"
        : "single-lots";

  return { pageName, limit };
}

function getViewAllLinkTo(auction: Auction) {
  if (auction.type.id === ID_AUCTION_TYPE.PERMANENT) {
    return path.store.shop();
  }
  if (auction.type.id === ID_AUCTION_TYPE.PRIVATE_SALE) {
    return path.store.privateSale();
  }
  if (isAuctionOfBarrel(auction)) {
    return path.weekly.value("barrels");
  }
  if (auction.type.id === ID_AUCTION_TYPE.COLLECTION) {
    return path.weekly.value("collections");
  }
  return path.weekly.value("single-lots");
}
