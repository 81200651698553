import { useState } from "react";
import { range } from "lodash";
import {
  AlertPopup,
  AlertPopupMeta,
} from "src/app/modules/Includes/LayoutSide/components/common/alert-popup";
import SkeletonCollectionCard from "src/app/modules/Includes/CustomerSide/components/skeleton/SkeletonCollectionCard";
import { NoBidsNew } from "src/app/modules/Includes/CustomerSide/components/common/NoBidsNew";
import { Navigation } from "../navigation";
import { useAuctionsQuery } from "src/app/api/auctions";
import { usePageTitle } from "src/app/hooks/usePageTitle";
import { StickyControls } from "src/app/components/sticky-controls";
import { PAGE_TITLE } from "src/app/const";
import { AuctionCardLg } from "src/app/components/auction-card/lg";

export const PageArchive = () => {
  usePageTitle(PAGE_TITLE.MY_ACTIVITIES());
  const [alertPopupMeta, setAlertPopupMeta] = useState<AlertPopupMeta | null>(
    null,
  );

  const auctionsQuery = useAuctionsQuery({ pageName: "archive" });

  const filteredAuctions = (auctionsQuery.data || []).filter(
    (auction) => !!auction.isArchive,
  );

  return (
    <div>
      {!!alertPopupMeta && (
        <AlertPopup
          alertPopupMeta={alertPopupMeta}
          hidePopup={() => setAlertPopupMeta(null)}
        />
      )}

      <StickyControls slot1={() => <Navigation />} />

      <div
        className="lots-collection-cards-container"
        style={{ marginBottom: "24px" }}
      >
        {(!!auctionsQuery.isLoading ||
          (!!auctionsQuery.isFetching && !filteredAuctions.length)) &&
          range(0, 4).map((i) => (
            <SkeletonCollectionCard key={`skel-card-arch-${i}`} />
          ))}

        {!auctionsQuery.isLoading &&
          !auctionsQuery.isFetching &&
          !filteredAuctions.length && <NoBidsNew title="Nothing found" />}

        {filteredAuctions.map((auction) => (
          <AuctionCardLg.Card
            key={`auction-${auction.id_auction}`}
            auction={auction}
            cardId={`auction-${auction.id_auction}`}
          />
        ))}
      </div>
    </div>
  );
};
