import { z } from "zod";
import { apiBidSchema } from "./Bid";

export const apiAuctionBaseSchema = z.object({
  bets: z.array(apiBidSchema),
  bid_percentage: z.number().nullish(),
  canBid: z.boolean(),
  catalogue: z.string().nullable(),
  closed: z.boolean().optional(),
  createdDate: z.string().optional(),
  created_data: z.string(),
  crurated_estimated_market_price: z.number(),
  currentPrice: z.number(),
  description: z.string().nullable(),
  disabled_for: z.string(),
  estimatedMarketPrice: z.number().optional(),
  finish_data: z.string().nullable(),
  id: z.number(),
  id_auction: z.number(),
  increment_selected: z.number(),
  initial_price: z.number(),
  isArchive: z.boolean(),
  isWishlist: z.boolean(),
  is_charity: z.boolean(),
  is_enabled_tech_fee: z.boolean(),
  is_multi_purchase: z.boolean().optional(),
  is_new: z.boolean().optional(),
  is_no_deposit: z.boolean().optional(),
  is_resell: z.boolean().optional(),
  is_sponsored: z.boolean(),
  lastBidDate: z.string().nullish(),
  more_information: z.string().nullable(),
  only_for_you: z.boolean(),
  processed: z.boolean(),
  short_description: z.string().nullable().optional(),
  stato: z.boolean(),
  tags: z.array(z.string()),
  tech_fee: z.number(),
  visible_for: z.string(),
  is_buy_now_enabled: z.boolean().nullable(),
  buy_now_price: z.number().nullable(),
});
