import { ButtonWish } from "./components/button-wish";
import { Select } from "./components/select";
import { useIsAuctionFinished } from "src/app/hooks/useIsAuctionFinished";
import { ButtonSubmit } from "./components/button-submit";
import { ButtonArchive } from "./components/button-archive";
import { isAuctionOfBarrel, isAuctionOfLot } from "src/app/models/auctions";
import { useCardCtx } from "../../card-context";
import { useMyUser } from "src/app/hooks/useMyUser";
import AddInfoAndBuyDialog from "../../atoms/add-info-and-buy-dialog";
import { useState } from "react";
import InfoMessage from "./components/info-message";
import { PiWarningDuotone } from "react-icons/pi";
import { useMedia } from "react-use";
import { FormCollection } from "./components/form-collection";
import { formatCurrency } from "src/app/utils/formatCurrency";
import { ButtonShare } from "../../atoms/button-share";
import { noop } from "lodash";

export const ForCollection = () => {
  const isWide = useMedia("(min-width: 1044px)");
  const cardCtx = useCardCtx();
  const isFinished = useIsAuctionFinished(cardCtx.auction);
  const isEdit = !cardCtx.auction.canBid && cardCtx.auction.canEdit;

  const user = useMyUser();
  const hasCard = !user.checkUser.is_missing_card;
  const [showAddressAndCardModal, setShowAddressAndCardModal] = useState<
    [boolean, () => void]
  >([false, noop]);

  const handleSubmit = (options?: { buyNow?: boolean }) => {
    const handlerToCall = options?.buyNow
      ? cardCtx.handleBuyNow
      : isEdit
        ? cardCtx.editBid
        : cardCtx.handleSubmit;

    if (!user.addressInvoice.length || !hasCard) {
      setShowAddressAndCardModal([true, handlerToCall]);
    } else {
      return handlerToCall();
    }
  };

  return (
    <>
      {!!showAddressAndCardModal[0] && (
        <AddInfoAndBuyDialog
          onClose={() => setShowAddressAndCardModal([false, noop])}
          onBuy={() => {
            showAddressAndCardModal[1]();
            setShowAddressAndCardModal([false, noop]);
          }}
        />
      )}
      <FormCollection
        actionButtons={
          <>
            <ButtonShare size={30} />
            <ButtonWish
              isWished={cardCtx.isWished}
              onClick={cardCtx.toggleIsWished}
              isLoading={cardCtx.isWishLoading}
              variant="ghost"
              size={30}
            />
            <ButtonArchive
              isArchived={cardCtx.isArchived}
              isLoading={cardCtx.isArchiveLoading}
              onClick={cardCtx.toggleArchive}
              variant="ghost"
              size={30}
            />
          </>
        }
        auction={cardCtx.auction}
        headingLabel={`Starting price per ${
          isAuctionOfBarrel(cardCtx.auction)
            ? `${cardCtx.auction.options.quantities.mul} liters`
            : "lot"
        }`}
        empLabel={`Crurated Estimated Market Price per ${
          isAuctionOfBarrel(cardCtx.auction)
            ? `${cardCtx.auction.options.quantities.mul} L`
            : "lot"
        }`}
        empTooltipContent="Estimated Market Price (EMP) is calculated by Crurated using a range of different sources, taking into account the condition and rarity of the bottles."
        totalLabel="Excl. of processing fees and taxes"
        totalTooltipContent="Excludes any processing fees, applicable taxes and/or duties and delivery charges. Please check our T&C or Contact the Customer Service for further details."
        inputQty={
          <Select
            label="Quantity"
            value={cardCtx.quantity}
            options={cardCtx.auction.quantityOptions}
            onChange={(option) => {
              cardCtx.setQuantity(option);
            }}
            disabled={
              isFinished ||
              (!cardCtx.auction.canBid && !cardCtx.auction.canEdit)
            }
          />
        }
        inputPrice={
          <Select
            label={`Your offer per ${isAuctionOfLot(cardCtx.auction) ? "lot" : `${cardCtx.auction.options.quantities.mul} L`}`}
            value={cardCtx.price}
            options={cardCtx.auction.priceOptions}
            onChange={cardCtx.setPrice}
            disabled={isFinished}
          />
        }
        action={
          <ButtonSubmit
            label={
              isEdit
                ? "Edit bid"
                : `Place a Bid at ${formatCurrency(cardCtx.price * cardCtx.quantity)}`
            }
            forbidden={
              !!(!cardCtx.auction.canBid || isFinished) &&
              !!user.addressInvoice.length &&
              !!hasCard &&
              !cardCtx.auction.canEdit
            }
            isLoading={cardCtx.isMakeABidLoading}
            onClick={handleSubmit}
          />
        }
        action2={
          !!cardCtx.auction.is_buy_now_enabled &&
          !!cardCtx.auction.buy_now_price &&
          (!!cardCtx.auction.canBid ||
            !user.addressInvoice.length ||
            !hasCard) && (
            <ButtonSubmit
              label={`Buy Now at ${formatCurrency(
                cardCtx.auction.buy_now_price * cardCtx.quantity,
              )}`}
              forbidden={false}
              isLoading={cardCtx.isMakeABidLoading}
              onClick={() => {
                handleSubmit({ buyNow: true });
              }}
            />
          )
        }
        infoMessage={
          !!cardCtx.auction.whyCantBid && (
            <InfoMessage
              isWide={isWide}
              title={cardCtx.auction.whyCantBid}
              icon={
                <PiWarningDuotone
                  style={{ display: "block", fontSize: 16 }}
                  color="#C87A06"
                />
              }
            />
          )
        }
      />
    </>
  );
};
